import React, { useEffect, useState } from "react";
import "../CSS/Ourfleet.css";
import { Row, Col, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

// Images
const BgBanner =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195062/banner_bg_ucdo2v_vmf1ts.webp";
const luggageIcon =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810960/luggage_fgbxjx.png";
const passengerIcon =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810964/passenger_q7ggc5.png";
const marcedesEclass2023 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720541964/E_Class_2023_h4hjcf.webp";
const Touran =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195122/fordtorus_fjxkwk_p6pmuz.webp";
const audiA6 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195056/audiA6_mta8a1_jrrlux.webp";
const BMW5 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720542144/BMW_5_2023_gaf0b1.webp";
const Tahoe =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720542535/Tahoe_2023_wfpksa.webp";
const Subarbon =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720542456/Chevrolet_Suburban_2023_lltxem.webp";
const GMC =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720542387/GMC_YUKON_2023_pyhjx2.webp";
const BMW7 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720543210/BMW_7_2023_dyfafb.webp";
const audiA8 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720543259/AUDI_A8_2023_iihnny.webp";
const marcedesSclass =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720543115/s_class_mercedes_2024_kyiofc.webp";
const marcedesSprinter =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720542623/mercedes_sprinter_2022_arjhh3.webp";
const VClass =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720542796/mercedes_v_class_2023_b7ivav.webp";

// Hompage fleet
const Economy = [
  {
    name: "Ford Tourus",
    image: Touran,
    type: "Sedan",
    example: "Ford",
    max: 3,
    luggage: 2,
  },
];
const Business = [
  {
    name: "Mercedes E Class",
    image: marcedesEclass2023,
    type: "Sedan",
    example: "EClass",
    max: 3,
    luggage: 2,
  },
  {
    name: "BMW 5",
    image: BMW5,
    type: "Sedan",
    example: "BME",
    max: 3,
    luggage: 2,
  },
  {
    name: "Audi A6",
    image: audiA6,
    type: "Sedan",
    example: "Audi",
    max: 3,
    luggage: 2,
  },
];
const Luxury = [
  {
    name: "Mercedes S Class",
    image: marcedesSclass,
    type: "Sedan",
    example: "Mercedes",
    max: 3,
    luggage: 2,
  },
  {
    name: "BMW 7",
    image: BMW7,
    type: "Sedan",
    example: "BMW 7",
    max: 3,
    luggage: 2,
  },
  {
    name: "Audi A8",
    image: audiA8,
    type: "Sedan",
    example: "Audi",
    max: 3,
    luggage: 2,
  },
];

// Our Fleet Page
const EconomyLong = [
  {
    name: "Ford Tourus",
    image: Touran,
    type: "Sedan",
    example: "Ford",
    max: 3,
    luggage: 2,
  },
];
const BusinessLong = [
  {
    name: "Mercedes E Class",
    image: marcedesEclass2023,
    type: "Sedan",
    example: "EClass",
    max: 3,
    luggage: 2,
  },
  {
    name: "BMW 5",
    image: BMW5,
    type: "Sedan",
    example: "BME",
    max: 3,
    luggage: 2,
  },
  {
    name: "Audi A6",
    image: audiA6,
    type: "Sedan",
    example: "Audi",
    max: 3,
    luggage: 2,
  },
  {
    name: "GMC Yukon",
    image: GMC,
    type: "SUV",
    example: "GMC Yukon",
    max: 7,
    luggage: 4,
  },
  {
    name: "Subarban",
    image: Subarbon,
    type: "SUV",
    example: "Subarbon",
    max: 7,
    luggage: 4,
  },
  {
    name: "Tahoe",
    image: Tahoe,
    type: "SUV",
    example: "Tahoe",
    max: 7,
    luggage: 4,
  },
  {
    name: "Mercedes Sprinter",
    image: marcedesSprinter,
    type: "Van",
    example: "Sprinter",
    max: 8,
    luggage: "25+",
  },
  {
    name: "Mercedes V Class",
    image: VClass,
    type: "Van",
    example: "V-Class",
    max: 7,
    luggage: "10+",
  },
];
const LuxuryLong = [
  {
    name: "Mercedes S Class",
    image: marcedesSclass,
    type: "Sedan",
    example: "Mercedes",
    max: 3,
    luggage: 2,
  },
  {
    name: "BMW 7",
    image: BMW7,
    type: "Sedan",
    example: "BMW 7",
    max: 3,
    luggage: 2,
  },
  {
    name: "Audi A8",
    image: audiA8,
    type: "Sedan",
    example: "Audi",
    max: 3,
    luggage: 2,
  },
];

function Ourfleet() {
  const location = useLocation();

  const [fleetype, setFleetType] = useState(null);
  const [selectedbtn, Setselectedbtn] = useState("Business");
  const [selectedarray, Setselectedarray] = useState(Business);
  const [selectedLong, setSelectedLong] = useState(BusinessLong);

  useEffect(() => {
    if (location.pathname === "/ourfleet") {
      setFleetType("/ourfleet");
    } else {
      setFleetType("/");
    }
  }, [location]);

  const handleclick = (value, array11) => {
    Setselectedbtn(value);
    if (location.pathname === "/ourfleet") {
      setSelectedLong(array11);
    } else {
      Setselectedarray(array11);
    }
  };

  return (
    <Container>
      <div className="Ourfleet_main_container">
        <div className="upper_div">
          <div className="left_div">
            <h1>
              Our <span>fleet</span>
            </h1>
            <p>
              You'll Never Settle If You Ride Luxury. Discover our wide range of
              Luxury Cars
            </p>
          </div>
          <div className="right_div">
            <button
              className={selectedbtn === "Business" ? "activebtn" : ""}
              onClick={() => {
                if (fleetype === "/ourfleet") {
                  handleclick("Business", BusinessLong);
                } else {
                  handleclick("Business", Business);
                }
              }}
            >
              Business
            </button>
            <button
              className={selectedbtn === "First Class" ? "activebtn" : ""}
              onClick={() => {
                if (fleetype === "/ourfleet") {
                  handleclick("First Class", LuxuryLong);
                } else {
                  handleclick("First Class", Luxury);
                }
              }}
            >
              First Class
            </button>
            <button
              className={selectedbtn === "Economy" ? "activebtn" : ""}
              onClick={() => {
                if (fleetype === "/ourfleet") {
                  handleclick("Economy", EconomyLong);
                } else {
                  handleclick("Economy", Economy);
                }
              }}
            >
              Economy
            </button>
          </div>
        </div>
        {fleetype === "/ourfleet" ? (
          <div className="lower_div_our_fleet">
            <Row className="align-items-center">
              {selectedLong.map((data, index) => (
                <Col md="4">
                  <div className="fleet_car_box">
                    <img
                      className="fleet_car_bg"
                      src={BgBanner}
                      alt="banner bg"
                    />
                    <h1 className="heading">{data.name}</h1>
                    <img
                      className="fleet_car_img"
                      src={data.image}
                      alt={`Car ${index + 1}`}
                    />
                  </div>
                  <div className="internal-details">
                    <div className="inner-box">
                      <img
                        className="key"
                        src={passengerIcon}
                        alt="passenger"
                      />
                      <span className="value">Passengers {data.max}</span>
                    </div>
                    <div className="inner-box">
                      <img className="key" src={luggageIcon} alt="luggage" />
                      <span className="value">Luggage x {data.luggage}</span>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <div className="lower_div">
            <Row className="align-items-center">
              {selectedarray.map((data, index) => (
                <Col md="4">
                  <div key={index} className="lower_div_item">
                    <h1>{data.name}</h1>
                    <img src={data.image} alt={`Car ${index + 1}`} />
                  </div>
                  <div className="internal-details">
                    <div className="inner-box">
                      <img
                        className="key"
                        src={passengerIcon}
                        alt="passenger"
                      />
                      <span className="value">Passengers {data.max}</span>
                    </div>
                    <div className="inner-box">
                      <img className="key" src={luggageIcon} alt="luggage" />
                      <span className="value">Luggage x {data.luggage}</span>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </Container>
  );
}

export default Ourfleet;
