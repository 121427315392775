import React from "react";
import "./style.css";

import Icon from "../../assets/whatsapp.png";

const WhatsAppContact = (props) => {
  return (
    <div className="container">
      <div className="inner">
        <a href={`https://wa.me/${props.number}`}>
          <img src={Icon} alt="whatsapp icon" />
        </a>
      </div>
    </div>
  );
};

export default WhatsAppContact;
