import React from "react";
import { Container } from "react-bootstrap";
import "./style.css";

const TermsContent = () => {
  return (
    <Container className="terms-content-box">
      <div className="section">
        <h2 className="heading1">1. Purchase of Services</h2>
        <p className="text-terms1">
          By placing an order through our Website, phone, or via email, you
          agree that you are legally capable of entering into binding contracts
          and you are at least over 18 years old.
        </p>
        <h2 className="heading">2. Reservations</h2>
        <p className="text-terms">
          The ECS Provider reservation team will make every effort to assist
          their clients 24/7. We will strive to exceed all expectations at all
          times, but 24 hours’ notice may be required to prepare the chauffeured
          vehicle for your specific journey. All prices quoted are in SAR, with
          hiring starting at the pickup point (A) and finishing at the drop-off
          point (B) except those hires to or from various airports or those that
          are the subject of a fixed charge.
        </p>
        <h2 className="heading">3. Online Bookings</h2>
        <p className="text-terms">
          All online bookings are subject to ECS Provider approval. Occasionally
          errors may occur where online maps & quotes are calculated
          incorrectly. In the instance where an error is made and a product is
          listed at an incorrect price, ECS Provider shall maintain the right to
          refund or cancel orders placed at the incorrect price. Prices and
          product availability are subject to change without notice.
        </p>
        <h2 className="heading">4. Privacy Policy</h2>
        <p className="text-terms">
          {" "}
          How your personal information is used: We will treat all your personal
          information as confidential and never disclose it to any third parties
          and will only use it in accordance with our Privacy Policy. When you
          book on this Website, we will ask you to input personal details to
          identify you and secure your bookings, such as your name, e-mail
          address, billing address, credit card, or other payment information.
        </p>
        <h2 className="heading">5. Single and Return Journeys</h2>
        <p className="text-terms">
          All return journeys on the same day are subject to waiting time
          charges if the return journey is within 3 hours or out of Saudi
          Arabia. If the return journey is scheduled within more than 3 hours,
          no waiting time charges apply. However, the return journey may be
          completed by a different driver.
        </p>
        <h2 className="heading">6. Waiting Time</h2>
        <p className="text-terms">
          All quotes for journeys collecting from a flight into a Saudi Arabian
          airport include 30 minutes of complimentary waiting time. All other
          journeys include 15 minutes complimentary waiting time. After the
          complimentary waiting time for all journeys, a charge calculated in 15
          min increments of SAR 50/hour will be applied to provided debit or
          credit card when the booking is made.
        </p>
        <h2 className="heading">7. Payments</h2>
        <p className="text-terms">
          All charges specified herein and in the current tariff published by
          ECS Provider are payable in advance. The credit or debit card you
          provide when making the booking will be charged for any additional
          charges, e.g., extra waiting time, parking charges.
        </p>
        <p className="text-terms">
          Credit or debit card payments have no surcharge. However, credit card
          charges are non-refundable for booking cancellations.
        </p>
        <p className="text-terms">
          All online quoted prices are inclusive of VAT.
        </p>
        <p className="text-terms">
          Your booking may be subject to additional waiting time and car park
          charges.
        </p>
        <p className="text-terms">
          Rates are subject to change at any time without prior written notice.
        </p>
        <h2 className="heading">8. Cancellation and Refund Policy</h2>
        <p className="text-terms">
          For all bookings (excluding wedding-related bookings), cancellations
          or amendments must be made and acknowledged by ECS Provider 24 hours
          before the scheduled pick-up time. Otherwise, ECS Provider reserves
          the right to charge the client for the full amount. This also applies
          to bookings made within 12 hours of the pickup time.
        </p>
        <h2 className="heading">9. No-Show Policy</h2>
        <p className="text-terms">
          If the customer fails to show at the designated location for which a
          reservation has been made and a confirmation was sent out, all monies
          paid will be non-refundable.
        </p>
        <h2 className="heading">10. Complaints</h2>
        <p className="text-terms">
          In the event of a complaint about the company’s services, the hirer
          should endeavor to seek a solution at the time by seeking assistance
          from the driver or from the company. If this has not provided a
          remedy, complaints should be submitted in writing and within 14 days
          of the termination date of the hire. The Company will acknowledge all
          complaints within 14 days and will normally reply fully within 28
          days.
        </p>
        <h2 className="heading">11. Fraud</h2>
        <p className="text-terms">
          Payments must be authorized by the cardholder named in the booking. We
          withhold the right to cancel your booking without prior notice if we
          reasonably suspect that you or the cardholder are associated with any
          kind of fraudulent activity. Such suspicion may be based on the
          following activities
        </p>
        <ul>
          <li className="text-terms">
            You cannot provide, when questioned, information about the itinerary
            such as full pick up and destination addresses, full passenger name
            and contact number or any other mandatory requirements by the
            company
          </li>
          <li className="text-terms">
            You cannot provide when questioned, contact information for the
            cardholder so that we may carry out security checks
          </li>
          <li className="text-terms">
            The cardholder did not authorize the payment and claims that the
            booking is fraudulent
          </li>
          <li className="text-terms">
            There has been previous fraudulent activity/chargebacks by you or
            the cardholder
          </li>
          <li className="text-terms">
            If we reasonably suspect that you or the cardholder are associated
            with any kind of fraudulent activity we reserve full rights to
            refuse any bookings and we may report you or the cardholder to the
            fraud department.
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default TermsContent;
