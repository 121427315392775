import React, { Fragment, useEffect } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import TextBanner from "../components/TextBanner";
import TermsContent from "../components/TermsContent";
import WhatsAppContact from "../components/WhatsAppContact";

const TermsAndConditions = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <Fragment>
      <WhatsAppContact number="+917002187747" />
      <Navbar />
      <TextBanner
        heading="Terms and conditions"
        subheading="All business between clients and ECS Provider in Riyadh Saudi Arabia is a transaction based on the terms and conditions defined here (on this website). When any client places an order, the assumption is that the client agrees to all the terms and conditions defined here (on this website)."
      />
      <TermsContent />
      <Footer />
    </Fragment>
  );
};

export default TermsAndConditions;
