import React, { useState, useEffect } from "react";
import "../CSS/Pymenycomponent.css";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "./Loader";
import {
  createSearchParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import useWindowDimensions from "../Utils/screenSize";
import CountryCodes from "../Utils/countryCodes.json";
import API from "../Utils/endpoints";
import moment from "moment";
import Swal from "sweetalert2";

// Icons
const rightredarrow =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810967/place-dir_yukaim.png";
const paymentIco =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810965/payment-ico_wp8fqn.png";
const backIcon =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810901/back_qywzwg.png";

const YourComponent = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location1 = useLocation();
  const [loader, setLoader] = useState(false);
  const [forminputs, setforminpts] = useState({});
  var [search] = new useSearchParams(location1.search);
  var inputvalues = {
    from: search.get("from"),
    to: search.get("to"),
    pickdate: search.get("pickdate"),
    picktime: search.get("picktime"),
    passenger: search.get("passenger"),
    car_name: search.get("car_name"),
    car_image: search.get("car_image"),
    car_category: search.get("car_category"),
    car_type: search.get("car_type"),
    car_passengers: search.get("car_passengers"),
    car_fare: search.get("car_fare"),
    airport: search.get("airport"),
    hours: search.get("hours"),
    bookType: search.get("bookType"),
    instant_confirm: false,
    cancel_policy: false,
  };

  const handleInputChange = (inputKey, value) => {
    setforminpts((prevInputs) => ({
      ...prevInputs,
      [inputKey]: value,
    }));
  };

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      window.scroll(0, 0);
    }, 2000);
  }, []);

  useEffect(() => {
    navigate({ pathname: location1.pathname });
  }, []);

  let pmtMsg = `
    <div style={{textAlign:left}}>
      <p>Thank you for booking with ECS PROVIDER!</p>
      <p>Your booking has been successfully received. Our Support team will contact you shortly to confirm the details and answer any questions you may have. If you need immediate assistance, please feel free to reach out to us directly.</p>
      <p>Email: info@ecsprovider.com</p>
      <p>Mobile: +91 70021 87747</p>
      <p>We appreciate your trust in ECS PROVIDER and look forward to serving you.</p>
    </div>
          `;

  const BoookingAPI = async (payType) => {
    setLoader(true);
    let response = await API.BookingAndCustomer(
      forminputs.customer_email,
      forminputs.customer_first_name,
      forminputs.customer_last_name,
      forminputs.airline_name,
      forminputs.flightnumber,
      forminputs.driver_pick_sign,
      forminputs.adiitional,
      inputvalues.picktime,
      inputvalues.pickdate,
      inputvalues.from,
      inputvalues.to,
      inputvalues.passenger,
      inputvalues.car_name,
      inputvalues.bookType,
      inputvalues.hours,
      payType
    );
    if (response.status === 200) {
      setLoader(false);
      if (payType === "paynow") {
        Swal.fire({
          title: "Succes!",
          html: pmtMsg,
          icon: "success",
          confirmButtonText: "Proceed to payment",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        }).then(async (res) => {
          if (res.isConfirmed) {
            navigate(
              {
                pathname: "/confirm-pay",
                search: createSearchParams({
                  amount: inputvalues.car_fare,
                  email: forminputs.customer_email,
                  from: inputvalues.from,
                  to: inputvalues.to,
                  time: inputvalues.picktime,
                  date: inputvalues.pickdate,
                  category: inputvalues.car_category,
                  type: inputvalues.car_type,
                }).toString(),
                state: inputvalues,
              },
              { replace: true }
            );
          }
        });
      } else {
        Swal.fire({
          title: "Succes!",
          html: pmtMsg,
          icon: "success",
          confirmButtonText: "Go to Home",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        }).then((res) => {
          if (res.isConfirmed) {
            navigate("/");
          }
        });
      }
    } else {
      setLoader(false);
      Swal.fire({
        title: "Error!",
        text: response.data.msg,
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#e12e2a",
        allowOutsideClick: false,
      });
    }
  };

  const handlebookbtn = async () => {
    setLoader(true);
    if (inputvalues.airport === "Airport Transfer") {
      if (Object.keys(forminputs).length >= 6) {
        if (forminputs.cancel_policy && forminputs.instant_confirm) {
          setLoader(false);
          if (forminputs.mobileno && forminputs.mobileno.length === 10) {
            Swal.fire({
              title: "Select Your Payment",
              text: "How you would like to pay for your ride",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Pay Now",
              confirmButtonColor: "#e12e2a",
              cancelButtonText: "Pay Later",
              cancelButtonColor: "#e12e2a",
              reverseButtons: true,
              allowOutsideClick: false,
            }).then((res) => {
              if (res.isConfirmed) {
                BoookingAPI("paynow");
              } else {
                BoookingAPI("paylater");
              }
            });
          } else {
            Swal.fire({
              title: "Invalid Mobile No.",
              text: "Please check mobile no.",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#e12e2a",
              allowOutsideClick: false,
              showCloseButton: true,
            });
          }
        } else {
          setLoader(false);
          Swal.fire({
            title: "Error!",
            text: "Please accept secure payment options.",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#e12e2a",
            allowOutsideClick: false,
          });
        }
      } else {
        setLoader(false);
        Swal.fire({
          title: "Error!",
          text: "Please fill all required fields.",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        });
      }
    } else {
      if (Object.keys(forminputs).length >= 4) {
        if (forminputs.cancel_policy && forminputs.instant_confirm) {
          setLoader(false);
          if (forminputs.mobileno && forminputs.mobileno.length === 10) {
            Swal.fire({
              title: "Schedule Your Ride",
              text: "Choose when you would like to book your ride",
              icon: "info",
              showCancelButton: true,
              confirmButtonText: "Pay Now",
              confirmButtonColor: "#e12e2a",
              cancelButtonText: "Pay Later",
              cancelButtonColor: "#e12e2a",
              reverseButtons: true,
              allowOutsideClick: false,
            }).then((res) => {
              if (res.isConfirmed) {
                BoookingAPI("paynow");
              } else {
                BoookingAPI("paylater");
              }
            });
          } else {
            Swal.fire({
              title: "Invalid Mobile No.",
              text: "Please check mobile no.",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#e12e2a",
              allowOutsideClick: false,
              showCloseButton: true,
            });
          }
        } else {
          setLoader(false);
          Swal.fire({
            title: "Error!",
            text: "Please accept secure payment options.",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#e12e2a",
            allowOutsideClick: false,
          });
        }
      } else {
        setLoader(false);
        Swal.fire({
          title: "Error!",
          text: "Please fill all required fields.",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        });
      }
    }
  };

  const getAMPM = () => {
    let time = Number(inputvalues?.picktime?.split(":")[0]);
    if (time >= 13 && time <= 24) {
      return "PM";
    } else {
      return "AM";
    }
  };

  const handleBack = () => {
    navigate("/", { replace: true });
    // navigate({
    //   pathname: "/booking",
    //   search: createSearchParams({
    //     picktime: inputvalues.picktime,
    //     pickdate: inputvalues.pickdate,
    //     passenger: inputvalues.passenger,
    //     from: inputvalues.from,
    //     to: inputvalues.to,
    //     car_name: inputvalues.car_name,
    //     car_image: inputvalues.car_image,
    //     car_category: inputvalues.car_category,
    //     car_type: inputvalues.car_type,
    //     car_passengers: inputvalues.car_passengers,
    //     car_fare: inputvalues.car_fare,
    //     airport: inputvalues.airport,
    //   }).toString(),
    //   state: inputvalues,
    // });
  };

  return (
    <>
      {loader && <Loader />}
      <Container>
        <div className="payment-main-container">
          <h1 className="mainheading">Final Step of Booking</h1>
          <p onClick={handleBack} className="back-btn">
            <img src={backIcon} alt="back" /> &nbsp; Back
          </p>
          <p className="maintext">
            Congratulations, you have picked one of our best values. Book today
            and guarantee this great price.
          </p>
          <div className="payment_inner_main_container">
            <Row>
              <Col md="6">
                <div className="left-container">
                  {/* First Div */}
                  <div className="first-div">
                    <p className="date-time">
                      {moment(inputvalues.pickdate).format("DD-MMM-YYYY")} -{" "}
                      {`${inputvalues.picktime} ${getAMPM()}`}
                    </p>
                    <p className="destination-details">
                      <span className="text">{inputvalues.from}</span>
                      <img
                        className="arrow-ico"
                        src={rightredarrow}
                        alt=""
                      />{" "}
                      <span className="text2">{inputvalues.to}</span>
                    </p>
                  </div>

                  {/* Second Div */}
                  <div className="second-div">
                    {width >= 1025 ? (
                      <Row>
                        <Col md="6">
                          <img
                            className="car-image"
                            src={inputvalues.car_image}
                            alt="Car Logo"
                          />
                        </Col>
                        <Col md="3">
                          <div className="car-info">
                            <h4 className="type">{inputvalues.car_name}</h4>
                            <h4 className="category">{`${inputvalues.car_category} ${inputvalues.car_type}`}</h4>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="passenger-info">
                            <h4 className="text1">
                              {inputvalues.car_passengers} Passengers
                            </h4>
                            <h4 className="text2">
                              {inputvalues.bookType === "Hourly"
                                ? inputvalues.car_fare * inputvalues.hours
                                : inputvalues.car_fare}{" "}
                              SAR
                            </h4>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <>
                        <Row>
                          <Col md="12">
                            <img
                              className="car-image"
                              src={inputvalues.car_image}
                              alt="Car Logo"
                            />
                          </Col>
                        </Row>
                        <div className="car-mobile-box">
                          <div className="car-info">
                            <h4 className="type">{inputvalues.car_name}</h4>
                            <h4 className="category">{`${inputvalues.car_category} ${inputvalues.car_type}`}</h4>
                          </div>
                          <div className="passenger-info">
                            <h4 className="text1">
                              {inputvalues.car_passengers} Passengers
                            </h4>
                            <h4 className="text2">
                              {inputvalues.car_fare} SAR
                            </h4>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/* Third Div */}
                  <div className="third-div">
                    <h2>Who’s Traveling?</h2>
                    <div className="input-fields">
                      <label>
                        Email <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Email"
                        onChange={(e) =>
                          handleInputChange("customer_email", e.target.value)
                        }
                        required
                      />
                      <br />
                      <label>
                        First Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="First Name"
                        onChange={(e) =>
                          handleInputChange(
                            "customer_first_name",
                            e.target.value
                          )
                        }
                      />
                      <br />
                      <label>
                        Last Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        onChange={(e) =>
                          handleInputChange(
                            "customer_last_name",
                            e.target.value
                          )
                        }
                      />
                      <br />
                      <label>
                        Mobile Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="mobile-input">
                        <select
                          onChange={(e) =>
                            handleInputChange("notype", e.target.value)
                          }
                        >
                          {CountryCodes.map((data, _idx) => (
                            <option key={_idx} value={data.dial_code}>
                              {data.emoji} {data.name}
                            </option>
                          ))}
                        </select>
                        <input
                          className="mobile"
                          type="text"
                          minLength={10}
                          maxLength={10}
                          pattern="/[0-9]/g"
                          placeholder="Mobile Number"
                          onChange={(e) =>
                            handleInputChange("mobileno", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* Fourth Div (Flight Information) */}
                  {inputvalues.airport === "Airport Transfer" ? (
                    <div className="flight-div">
                      <h2>Flight Information</h2>
                      <p>
                        If you do not know or have a flight, enter in any
                        airline and flight number, then leave instructions about
                        your pickup in the Additional notes field below.
                      </p>
                      <div className="input-fields">
                        <Row>
                          <Col md="6">
                            <label>
                              Airline <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Airline"
                              onChange={(e) =>
                                handleInputChange(
                                  "airline_name",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label>
                              Flight Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Flight Number"
                              onChange={(e) =>
                                handleInputChange(
                                  "flightnumber",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* Fifth Div (Driver Information) */}
                  <div className="driver-div">
                    <h2>Driver Information</h2>
                    <div className="input-fields">
                      <label>Driver Pickup Sign</label>
                      <input
                        type="text"
                        placeholder="Driver Pick Up Sign"
                        onChange={(e) =>
                          handleInputChange("driver_pick_sign", e.target.value)
                        }
                      />
                      <label>Add a note to driver (Additional request)</label>
                      <textarea
                        placeholder="Add a note to the driver (Additional requests)"
                        rows="5"
                        onChange={(e) =>
                          handleInputChange("adiitional", e.target.value)
                        }
                      ></textarea>
                      <span className="slogn">
                        Extra Charge(Child,Seats,Wheel Chair,Animals)/10USD
                      </span>
                    </div>
                  </div>

                  {/* ... (Add other Divs if needed) */}
                </div>
              </Col>
              <Col md="6">
                <div className="right-container">
                  {/* Sixth Div */}
                  <div className="secure-payment-div">
                    <h2>
                      <img src={paymentIco} alt="Payment-Ico" /> Secure Payment
                    </h2>
                    <div className="check">
                      <input
                        type="checkbox"
                        id="instant-confirmation"
                        name="instant_confirm"
                        onChange={(e) =>
                          handleInputChange("instant_confirm", e.target.checked)
                        }
                      />
                      <label htmlFor="instant-confirmation">
                        Instant Confirmation
                      </label>
                    </div>
                    <p>
                      By clicking "Book Ride" you agree for auto-registration{" "}
                      <span>Terms of Service</span>. Change of plan? No worries
                      with our <span>Cancellation policy</span>.
                    </p>
                    <div className="check">
                      <input
                        type="checkbox"
                        id="accept-terms"
                        name="cancel_policy"
                        onChange={(e) =>
                          handleInputChange("cancel_policy", e.target.checked)
                        }
                      />
                      <label htmlFor="accept-terms">
                        Accepting Terms & Conditions
                      </label>
                    </div>
                    <p>
                      By accepting 'Terms & Condition' you agree to receive
                      informational, promotionals & newsletter emails from
                      LimoFahr Privacy Policy.
                    </p>
                    <button onClick={handlebookbtn}>
                      Book Ride For {inputvalues.car_fare} SAR
                    </button>
                  </div>

                  {/* Seventh Div */}
                  <div className="trusted-since-div">
                    <h2>TRUSTED SINCE 2021</h2>
                    <p>
                      Pre-book your ride and save! Customers who book with
                      limofahr.com save on average 30% from Taxi or Ride Share
                      hailing at the airport due to surge pricing and other
                      factors.
                    </p>
                    <p>
                      Traveling with children? In most countries, it’s illegal
                      for children under 5 years to ride without a baby seat,
                      child seat, or booster seat. Please let us know how many
                      child safety seats are required, so we can tell your
                      driver ahead of your trip. We appreciate about 24 hours
                      advance notice to arrange this, as child seats are in
                      short supply.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default YourComponent;
