import React from "react";
import { Container } from "react-bootstrap";
import "./style.css";

const PrivacyContent = () => {
  return (
    <Container className="privacy-content-box">
      <div className="section1">
        <p>
          It applies to all guests and visitors, known through this document as
          “data subjects”. ECS PROVIDER is a "data controller". This means that
          we are responsible for deciding how we hold and use personal
          information about you. We are required under data protection
          legislation to notify you of the information contained in this privacy
          statement. This statement applies to data subjects. This statement
          does not form part of any contract to provide services. We may update
          this statement at any time. It is important that you read this
          statement, together with any other privacy statement we may provide on
          specific occasions when we are collecting or processing personal
          information about you, so that you are aware of how and why we are
          using such information.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Data protection principles</h2>
        <p className="text-privacy">
          We will comply with data protection law. This says that the personal
          information we hold about you must be:
        </p>
        <ul>
          <li className="text-privacy">
            Used lawfully, fairly and in a transparent way.
          </li>
          <li className="text-privacy">
            Collected only for valid purposes that we have clearly explained to
            you and not used in any way that is incompatible with those
            purposes.
          </li>
          <li className="text-privacy">
            Relevant to the purposes we have told you about and limited only to
            those purposes.
          </li>
          <li className="text-privacy">Accurate and kept up to date.</li>
          <li className="text-privacy">
            Kept only as long as necessary for the purposes we have told you
            about. Kept securely.
          </li>
        </ul>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          The kind of information we hold about you
        </h2>
        <p className="text-privacy">
          We will comply with data protection law. This says that the personal
          information we hold about you must be:
        </p>
        <ul>
          <li className="text-privacy">
            Personal contact details such as name, title, addresses, telephone
            numbers, and personal email addresses.
          </li>
          <li className="text-privacy">
            Date of birth ; Gender; Marital status ; Next of kin and emergency
            contact information.
          </li>
          <li className="text-privacy">
            National Insurance or TAX ID number/PAN card ; Bank account details
            and tax status information.
          </li>
          <li className="text-privacy">
            Driving license; Grievance information; CCTV footage and other
            information obtained through electronic means such as door access
            records.
          </li>
          <li className="text-privacy">
            Information about your use of our information and communications
            systems ; Photographs;
          </li>
          <li className="text-privacy">
            We may also collect, store and use the following "special
            categories" of more sensitive personal information:
          </li>
          <li className="text-privacy">
            Information about your race or ethnicity, religious beliefs, sexual
            orientation
          </li>
        </ul>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          How is your personal information collected?
        </h2>
        <p className="text-privacy">
          We collect personal information about data subjects through various
          methods; check-ins, bookings, and reservations. Either directly from
          data subjects or sometimes from a third party acting on our behalf
          (for example through a booking agency, travel agent or corporate
          account)
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          How we will use information about you
        </h2>
        <p className="text-privacy">
          We will only use your personal information when the law allows us to.
          Most commonly, we will use your personal information in the following
          circumstances:
        </p>
        <ul>
          <li className="text-privacy">
            Where we need to perform the contract we have entered into with you.
          </li>
          <li className="text-privacy">
            Where we need to comply with a legal obligation.
          </li>
          <li className="text-privacy">
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests.
          </li>
          <li className="text-privacy">
            Where you have given us explicit consent to do so.
          </li>
        </ul>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          We may also use your personal information in the following situations,
          which are likely to be rare:
        </h2>
        <ul>
          <li className="text-privacy">
            Where we need to protect your interests (or someone else's
            interests).
          </li>
          <li className="text-privacy">
            Where it is needed in the public interest or for official purposes
            or requested for by the CBI, police or governmental authorities.
          </li>
        </ul>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          Situations in which we will use your personal information
        </h2>
        <ul>
          <li className="text-privacy">
            We need all the categories of information in the list above
            primarily to allow us to perform our contract with you and to enable
            us to comply with legal obligations. In some cases, we may use your
            personal information to pursue legitimate interests of our own or
            those of third parties
          </li>
          <li className="text-privacy">
            Administering the contract, we have entered into with you.
          </li>
          <li className="text-privacy">
            Business management and planning, including accounting and auditing.
            Making decisions about grievances.
          </li>
          <li className="text-privacy">
            Making arrangements for the termination of a contract.
          </li>
          <li className="text-privacy">
            Dealing with legal disputes and insurance claims.
          </li>
          <li className="text-privacy">To prevent fraud.</li>
          <li className="text-privacy">
            To monitor your use of our information and communication systems to
            ensure compliance with Global IT policy and laws of the land.
          </li>
          <li className="text-privacy">
            To ensure network and information security, including preventing
            unauthorized access to our computer and electronic communications
            systems and preventing malicious software distribution.
          </li>
          <li className="text-privacy">
            To conduct data analytic studies to review and better understand
            customer satisfaction and needs. Some of the above grounds for
            processing will overlap and there may be several grounds which
            justify our use of your personal information.
          </li>
        </ul>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          If you fail to provide personal information
        </h2>
        <p className="text-privacy">
          If you fail to provide certain information when requested, we may not
          be able to perform the contract we have entered into with you (such as
          providing a benefit), or we may be prevented from complying with our
          legal obligations (such as proof of nationality for example passport)
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Change of purpose</h2>
        <p className="text-privacy">
          We will only use your personal information for the purposes for which
          we collected it, unless we reasonably consider that we need to use it
          for another reason and that reason is compatible with the original
          purpose. If we need to use your personal information for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so. Please note that we may process your personal
          information without your knowledge or consent, in compliance with the
          above rules, where this is required or permitted by law.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          How we use particularly sensitive personal information
        </h2>
        <p className="text-privacy">
          "Special categories" of particularly sensitive personal information
          require higher levels of protection. We need to have further
          justification for collecting, storing and using this type of personal
          information. We may process special categories of personal information
          in the following circumstances:
        </p>
        <ul>
          <li className="text-privacy">
            In limited circumstances, with your explicit written consent.
          </li>
          <li className="text-privacy">
            Where we need to carry out our legal obligations
          </li>
          <li className="text-privacy">
            Where it is needed in the public interest, such as for requested for
            by CBI, police or governmental authorities
          </li>
        </ul>
        <p className="text-privacy">
          Less commonly, we may process this type of information where it is
          needed in relation to legal claims or where it is needed to protect
          your interests (or someone else's interests) and you are not capable
          of giving your consent, or where you have already made the information
          public.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Principles of GDPR</h2>
        <p className="text-privacy">
          We will use your personal data only in-line with the following
          principles:
        </p>
        <ul>
          <li className="text-privacy">
            Lawfulness, fairness and transparency
          </li>
          <li className="text-privacy">Purpose limitation</li>
          <li className="text-privacy">Data minimization</li>
          <li className="text-privacy">Accuracy</li>
          <li className="text-privacy">Storage limitation</li>
          <li className="text-privacy">Integrity and confidentiality</li>
        </ul>
        <p className="text-privacy">
          This is the only principle that deals explicitly with security. The
          GDPR states that personal data must be “processed in a manner that
          ensures appropriate security of the personal data, including
          protection against unauthorized or unlawful processing and against
          accidental loss, destruction or damage, using appropriate technical or
          organizational measures”. The GDPR is deliberately vague about what
          measures organizations should take, because technological and
          organizational best practices are constantly changing. Currently,
          organizations should encrypt and/or pseudoscience personal data
          wherever possible, but they should also consider whatever other
          options are suitable.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Automated decision-making</h2>
        <p className="text-privacy">
          Automated decision-making takes place when an electronic system uses
          personal information to make a decision without human intervention. We
          do not envisage that any decisions will be taken about you using
          automated means, however we will notify you in writing if this
          position changes.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Data sharing</h2>
        <p className="text-privacy">
          We may have to share your data with third parties, including
          third-party service providers and other entities in ECS PROVIDER. We
          require appointed third parties to respect the security of your data
          and to treat it in accordance with the law. If we do, you can expect
          the same degree of protection in respect of your personal information.
          Data will only be transferred within the ECS PROVIDER.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          Why might you share my personal information with third parties?
        </h2>
        <p className="text-privacy">
          We will share your personal information with third parties where
          required by law, or where we have another legitimate interest in doing
          so, or when we have your explicit consent.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          Which third-party service providers process my personal information?
        </h2>
        <p className="text-privacy">
          "Third parties" includes third-party service providers (including
          contractors and designated agents) and other entities within our group
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          How secure is my information with third-party service providers and
          other entities in our group?
        </h2>
        <p className="text-privacy">
          All our third-party service providers and other entities in the group
          are required to take appropriate security measures to protect your
          personal information in line with our policies. We do not allow our
          third-party service providers to use your personal data for their own
          purposes. We only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          When might you share my personal information with other entities in
          the group?
        </h2>
        <p className="text-privacy">
          We may share your personal information with other entities in the ECS
          PROVIDER as part of our regular reporting activities on company
          performance, in the context of system maintenance, support and hosting
          of data.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">What about other third parties?</h2>
        <p className="text-privacy">
          We may share your personal information with other third parties, for
          example in the context of the possible sale or restructuring of the
          business. We may also need to share your personal information with a
          regulator or to otherwise comply with the law.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Data security</h2>
        <p className="text-privacy">
          We have put in place measures to protect the security of your
          information. Third parties will only process your personal information
          on our instructions and where they have agreed to treat the
          information confidentially, legally and securely. We have put in place
          appropriate security measures to prevent your personal information
          from being accidentally lost, used or accessed in an unauthorized way,
          altered or disclosed. In addition, we limit access to your personal
          information to those employees, agents, contractors and other third
          parties who have a business need to know. They will only process your
          personal information on our instructions and they are subject to a
          duty of confidentiality. We have put in place procedures to deal with
          any suspected data security breach and will notify you and any
          applicable regulator of a suspected breach where we are legally
          required to do so.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Data retention</h2>
        <p className="text-privacy">
          How long will you use my information for?
        </p>
        <p className="text-privacy">
          We will only retain your personal information for as long as necessary
          to fulfill the purposes we collected it for, including for the
          purposes of satisfying any legal, accounting, or reporting
          requirements. To determine the appropriate retention period for
          personal data, we consider the amount, nature, and sensitivity of the
          personal data, the potential risk of harm from unauthorized use or
          disclosure of your personal data, the purposes for which we process
          your personal data and whether we can achieve those purposes through
          other means, and the applicable legal requirements.
        </p>
        <p className="text-privacy">
          In some circumstances we may anonymous your personal information so
          that it can no longer be associated with you, in which case we may use
          such information without further statement to you.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          Rights of access, correction, erasure, and restriction
        </h2>
        <p className="text-privacy">
          Your duty to inform us of changes - It is important that the personal
          information we hold about you is accurate and current. Please keep us
          informed if your personal information changes during your relationship
          with us.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">
          Your rights in connection with personal information
        </h2>
        <p className="text-privacy">
          Under certain circumstances, by law you have the right to:
        </p>
        <p className="text-privacy">
          Request access to your personal information (commonly known as a "data
          subject access request"). This enables you to receive a copy of the
          personal information we hold about you and to check that we are
          lawfully processing it.
        </p>
        <p className="text-privacy">
          Request correction of the personal information that we hold about you.
          This enables you to have any incomplete or inaccurate information we
          hold about you corrected.
        </p>
        <p className="text-privacy">
          Request erasure of your personal information. This enables you to ask
          us to delete or remove personal information where there is no good
          reason for us continuing to process it. You also have the right to ask
          us to delete or remove your personal information where you have
          exercised your right to object to processing (see below).
        </p>
        <p className="text-privacy">
          Object to processing of your personal information where we are relying
          on a legitimate interest (or those of a third party) and there is
          something about your particular situation which makes you want to
          object to processing on this ground. You also have the right to object
          where we are processing your personal information for direct marketing
          purposes.
        </p>
        <p className="text-privacy">
          Request the restriction of processing of your personal information.
          This enables you to ask us to suspend the processing of personal
          information about you, for example if you want us to establish its
          accuracy or the reason for processing it.
        </p>
        <p className="text-privacy">
          If you want to review, verify, correct or request erasure of your
          personal information, object to the processing of your personal data,
          or request that we transfer a copy of your personal information to
          another party, please contact <span>info@ecsprovider.com</span> in
          writing.
        </p>
        <p className="text-privacy">No fee is usually required</p>
        <p className="text-privacy">
          You will not have to pay a fee to access your personal information (or
          to exercise any of the other rights). However, we may charge a
          reasonable fee if your request for access is clearly unfounded or
          excessive. Alternatively, we may refuse to comply with the request in
          such circumstances.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">What we may need from you</h2>
        <p className="text-privacy">
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access the information
          (or to exercise any of your other rights). This is another appropriate
          security measure to ensure that personal information is not disclosed
          to any person who has no right to receive it.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Right to withdraw consent</h2>
        <p className="text-privacy">
          In the limited circumstances where you may have provided your consent
          to the collection, processing and transfer of your personal
          information for a specific purpose, you have the right to withdraw
          your consent for that specific processing at any time. To withdraw
          your consent, please contact <span>info@ecsprovider.com</span> .Once
          we have received notification that you have withdrawn your consent, we
          will no longer process your information for the purpose or purposes
          you originally agreed to, unless we have another legitimate basis for
          doing so in law.
        </p>
        <p className="text-privacy">
          You have the right to make a complaint at concerned authority.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Changes to this privacy statement</h2>
        <p className="text-privacy">
          We reserve the right to update this privacy statement at any time, and
          we will provide you with a new privacy statement when we make any
          substantial updates. We may also notify you in other ways from time to
          time about the processing of your personal information.
        </p>
      </div>
      <div className="section">
        <h2 className="heading-privacy">Contact Us</h2>
        <p className="text-privacy">
          In case of any questions or suggestions regarding the Policy, please
          email ECS PROVIDER at: <span>info@ecsprovider.com</span>
        </p>
      </div>
    </Container>
  );
};

export default PrivacyContent;
