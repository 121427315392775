import React from "react";
import "../CSS/page2.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PaymentDetails from "../components/Paymentcomponent";
import WhatsAppContact from "../components/WhatsAppContact";

function Paymentpage() {
  return (
    <div className="payment_page">
      <WhatsAppContact number="+917002187747" />
      <NavBar />
      <PaymentDetails />
      <Footer />
    </div>
  );
}

export default Paymentpage;
