import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutForm } from "./checkoutForm";
import WhatsAppContact from "../../components/WhatsAppContact";

const StripePaymentForm = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  const location = useLocation();
  let [search] = new useSearchParams(location.search);
  let data = {
    totalAmount: search.get("amount"),
  };

  const options = {
    mode: "payment",
    amount: Number(data.totalAmount),
    currency: "sar",
  };

  return (
    <>
      <WhatsAppContact number="+917002187747" />
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm amount={data.totalAmount} />
      </Elements>
    </>
  );
};

export default StripePaymentForm;
