import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../CSS/BookLuxurious.css";
import { useNavigate } from "react-router-dom";
import getWindowDimensions from "../Utils/screenSize";

// Images
const luxury1 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195136/luxury1_dhahmg_z4ngmy_nqktz5.webp";
const luxury2 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195137/luxury2_aaulmi_fobxsv_stryjz.webp";
const luxury3 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195137/luxury3_vv80sg_nrwedy_bjcxto.webp";
const luxury4 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195138/luxury4_x13efr_n53jde_s6wu92.webp";
const luxury5 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195142/luxury5_w5e2oa_v9csmq_heeesd.webp";
const luxury6 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195141/luxury6_qpurfv_yi0oti_mmoxfw.webp";

const luxury1_mob =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058453/215-265_f_r5jp5q.webp";
const luxury2_mob =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058453/215-265_e_s40isu.webp";
const luxury3_mob =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058453/215-265_d_uwm0hf.webp";
const luxury4_mob =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058452/215-265_c_uxladh.webp";
const luxury5_mob =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058452/215-265_b_n5hijl.webp";
const luxury6_mob =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058452/215-265_a_gfiksr.webp";

const luxuriesDesktop = [
  {
    image: luxury1,
    name: "Riyadh",
  },
  {
    image: luxury2,
    name: "Makkah",
  },
  {
    image: luxury3,
    name: "Dubai",
  },
  {
    image: luxury4,
    name: "Madina",
  },
  {
    image: luxury5,
    name: "Jeddah",
  },
  {
    image: luxury6,
    name: "AL Khabar",
  },
];

const luxuriesMobile = [
  {
    image: luxury1_mob,
    name: "Riyadh",
  },
  {
    image: luxury2_mob,
    name: "Makkah",
  },
  {
    image: luxury3_mob,
    name: "Dubai",
  },
  {
    image: luxury4_mob,
    name: "Madina",
  },
  {
    image: luxury5_mob,
    name: "Jeddah",
  },
  {
    image: luxury6_mob,
    name: "AL Khabar",
  },
];

const BookLuxurious = () => {
  const navigate = useNavigate();
  const { width } = getWindowDimensions();

  const handleRoute = () => navigate("/ourfleet");

  const luxuries = width < 1024 ? luxuriesMobile : luxuriesDesktop;

  return (
    <Container className="luxurious_main_container">
      <div className="header_box">
        <h1>
          Book Your next <span>luxurious Trip</span>
        </h1>
        <p>
          Help us work better by giving your valuable feedback for the overall
          journey experience.
        </p>
      </div>
      <div className="luxurious_box">
        <Row>
          {luxuries.length &&
            luxuries.map((data, _idx) => (
              <Col xs="6" sm="6" md="4">
                <div className="luxuries_inner_box">
                  <img
                    src={data.image}
                    alt={data.name}
                    className="luxury_img"
                  />
                  <p className="luxury_text">{data.name}</p>
                </div>
              </Col>
            ))}
        </Row>
        {/* <div className="luxury_btn">
          <div className="btn_inner" onClick={handleRoute}>
            Book Your Fleet Now
          </div>
        </div> */}
      </div>
    </Container>
  );
};

export default BookLuxurious;
