import React from "react";
import "../CSS/Services.css";
import getWindowDimensions from "../Utils/screenSize";

// Images
const Services1Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195153/service1_mtrb3z_sehk9n_tkkcwa.webp";
const Services2Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195153/service2_djb7ca_igm0dy_k27vaf.webp";
const Services3Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195154/service3_d51v86_emxccj_zvqloo.webp";
const Services4Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195154/service4_b8wbpp_kwoymu_topdyc.webp";
const Services5Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195156/service5_yxb9tw_mbsps1_pc9dxm.webp";
const Services6Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195157/service6_nuorep_umcavu_o9byam.webp";

const Services1Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721059228/170-95_a_k74kzd.webp";
const Services2Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721059228/170-95_b_ncesf4.webp";
const Services3Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721059229/170-95_c_yom94t.webp";
const Services4Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721059229/170-95_d_a2zqu9.webp";
const Services5Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721059231/170-95_e_stdwfv.webp";
const Services6Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721059231/170-95_f_dhpu6g.webp";

const Services = () => {
  const { width } = getWindowDimensions();

  return (
    <div className="main_services_container">
      <div className="content-container">
        <h1>
          <span className="headingpart1">Your Experience is What</span>
          <span className="headingpart2">Matters the Most!</span>
        </h1>
        <p>
          Whatever your need, Executive Chauffeur Service is there when it comes
          to reliable transportation in the Kingdom of Saudi Arabia. We offer a
          complete range of superior limousine & car services for any day, any
          occasion.
        </p>
      </div>
      <div className="grid-container">
        <div className={`grid-item`}>
          <div className="image_container">
            <img
              src={width > 1024 ? Services1Desktop : Services1Mobile}
              alt="s1"
            />
          </div>
          <div className="text">
            <h3>Executive Corporate Travel</h3>
            <p>Professional solutions for corporate travelers.</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img
              src={width > 1024 ? Services3Desktop : Services3Mobile}
              alt="s2"
            />
          </div>
          <div className="text">
            <h3>High-end Roadshow Transportation</h3>
            <p>Specialist roadshow and tour transportation</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img
              src={width > 1024 ? Services4Desktop : Services4Mobile}
              alt="s3"
            />
          </div>
          <div className="text">
            <h3>Premium Airport Transfers</h3>
            <p>The most comfortable way to start or end your journey.</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img
              src={width > 1024 ? Services5Desktop : Services5Mobile}
              alt="s4"
            />
          </div>
          <div className="text">
            <h3>Sophisticated Event Transportation</h3>
            <p>Expertly executed event transportation</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img
              src={width > 1024 ? Services6Desktop : Services6Mobile}
              alt="s5"
            />
          </div>
          <div className="text">
            <h3>VIP Transport Solution</h3>
            <p>Personalized service to meet the Security of our VIPs.</p>
          </div>
        </div>
        <div className={`grid-item`}>
          <div className="image_container">
            <img
              src={width > 1024 ? Services2Desktop : Services2Mobile}
              alt="s6"
            />
          </div>
          <div className="text">
            <h3>Tourist Transportation Solution</h3>
            <p>
              We provide personalized tourist private transportation all over
              the Kingdom of Saudi Arabia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
