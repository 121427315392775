import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/OurServices.css";
import NavBar from "../components/NavBar";
import Header from "../components/header";
import Footer from "../components/Footer";
import WhatsAppContact from "../components/WhatsAppContact";

const ServicesBanner =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195158/services_banner_qt5nfr_xctjta.webp";
const Services1 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195159/services1_kvj1xr_fmhcdx.webp";
const Services2 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195161/services2_cnzw2a_g8o2ek.webp";
const Services3 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195161/services3_laspiq_c9vctv.webp";
const Services4 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195162/services4_eotens_iw8raw.webp";
const Services6 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195166/services6_rzljne_hzoxkq.webp";

function Services() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <div
      className="main_all_services_container"
      style={{ backgroundColor: "#FFF" }}
    >
      <WhatsAppContact number="+917002187747" />
      <NavBar cname="Our Services"></NavBar>
      <Header heading="Our Services" banner={ServicesBanner}></Header>
      <Container className="main_body_services_1">
        <Row className="align-items-center">
          <Col md="4">
            <img className="service-img" src={Services1} alt="Service 1" />
          </Col>
          <Col md="8">
            <div className="service_details">
              <h1 className="service_title">Airport</h1>
              <h1 className="service_subtitle">Transfers</h1>
              <p className="service_description">
                Whether you're traveling for business or pleasure, our
                professional chauffeurs are dedicated to ensuring that you reach
                your destination safely, comfortably, and on time.
              </p>
              <p className="service_description">
                From the moment you land, our team will be there to greet you
                with a warm welcome and assist you with your luggage. You can
                relax and unwind in the luxurious comfort of our vehicles while
                we navigate through traffic and handle all the details of your
                transfer.
              </p>
              <p className="service_description">
                For departures, we understand the importance of punctuality. Our
                chauffeurs will pick you up from your location with ample time
                to spare, ensuring a smooth journey to the airport so you can
                focus on your upcoming travels.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md="8">
            <div className="service_details">
              <h1 className="service_title">Vip</h1>
              <h1 className="service_subtitle">Assistance</h1>
              <p className="service_description">
                Whether you're a high-profile executive, a discerning traveler,
                or a celebrity in need of discreet and personalized support, our
                team is dedicated to exceeding your expectations at every turn.
              </p>
              <p className="service_description">
                From the moment you book with us, our VIP concierge will work
                tirelessly to anticipate and fulfill your every need. Whether
                it's arranging private airport lounges, coordinating special
                requests, or providing personalized amenities in our vehicles,
                we go above and beyond to ensure your comfort and satisfaction.
                Our highly trained VIP assistants are experts in the art of
                hospitality, providing attentive and discreet service to cater
                to your unique preferences.
              </p>
            </div>
          </Col>
          <Col md="4">
            <img src={Services2} alt="Service 2" />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md="4">
            <img src={Services3} alt="Service 3" />
          </Col>
          <Col md="8">
            <div className="service_details">
              <h1 className="service_title">Private</h1>
              <h1 className="service_subtitle">Chauffeurs</h1>
              <p className="service_description">
                Celebrate Every Mile: Your Personal Chauffeurs Await Embark on a
                journey of indulgence and convenience with our exclusive private
                chauffeur services at ECS. Crafted for those who demand nothing
                but the best, our chauffeurs aren't just drivers – they're your
                partners in creating unforgettable moments on the road. Step
                into luxury with our meticulously maintained fleet, ranging from
                sleek sedans to lavish limousines. Whether you're headed to a
                high-stakes meeting or a night on the town, our chauffeurs
                ensure a smooth and stylish ride, tailored precisely to your
                preferences. Experience true VIP treatment as our chauffeurs
                anticipate your needs, from adjusting the music to recommending
                hidden gems along the way.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md="8">
            <div className="service_details">
              <h1 className="service_title">Events</h1>
              <p className="service_description">
                Make a grand entrance at your next event with the unparalleled
                luxury and convenience of our chauffeur services at ECS. Whether
                it's a glamorous gala, a prestigious awards ceremony, or a
                stylish wedding, we're here to ensure that your transportation
                is as unforgettable as the event itself. Our fleet of premium
                vehicles, driven by professional chauffeurs, is at your
                disposal, offering a range of options to suit your style and
                group size. From sophisticated sedans to spacious SUVs, we have
                the perfect vehicle to complement your occasion and leave a
                lasting impression.
              </p>
            </div>
          </Col>
          <Col md="4">
            <img src={Services4} alt="Service 4" />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md="4">
            <img src={Services6} alt="Service 6" />
          </Col>
          <Col md="8">
            <div className="service_details">
              <h1 className="service_title">Trip</h1>
              <h1 className="service_subtitle">Service</h1>
              <p className="service_description">
                Experience travel like never before with our comprehensive trip
                service at [Your Company Name]. Whether you're embarking on a
                leisurely getaway or a bustling business trip, we're here to
                ensure that every aspect of your journey is smooth, convenient,
                and tailored to your needs. With our commitment to excellence
                and customer satisfaction, you can trust [Your Company Name] to
                deliver a seamless travel experience from the moment you book
                with us. Sit back, relax, and let us take care of the journey,
                so you can focus on making memories that last a lifetime.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Services;
