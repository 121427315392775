import React, { useState } from "react";
import "../CSS/Ourclients.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Images
const client1 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195107/client1_lsfghf_dhnl7m.webp";
const client2 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195108/client2_sq64r1_f3sfvd.webp";
const client3 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195108/client3_yae9ek_tyjzzw.webp";
const client4 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195108/client4_dy4dg1_odorhd.webp";
const client5 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195111/client5_sk2yjq_ys14uo.webp";
const client6 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195111/client6_qjgxw1_jeb8vu.webp";

const clients = [
  {
    image: client1,
  },
  {
    image: client2,
  },
  {
    image: client3,
  },
  {
    image: client4,
  },
  {
    image: client5,
  },
  {
    image: client6,
  },
];

const OurClients = () => {
  const navigate = useNavigate();

  const handleRoute = () => navigate("/contactus");

  return (
    <div className="clients_main_container">
      <Container className="clients_inner_container">
        <Row className="align-items-center">
          <Col md="4">
            <p className="text1">
              The catalysts of collaborations <br />
              <span>Meet our Partners.</span>
            </p>
            <p className="text2">
              Help us work better by giving your valuable feedback for the
              overall journey experience.
            </p>
            <div className="contact_btn">
              <div className="btn_box" onClick={handleRoute}>
                Contact Now
              </div>
            </div>
          </Col>
          <Col md="8">
            <Row className="align-items-center">
              {clients.length &&
                clients.map((data, _idx) => (
                  <Col xs="6" sm="6" md="4">
                    <img
                      src={data.image}
                      alt={`client ${_idx}`}
                      className="client_img"
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurClients;
