import React from "react";
import "../CSS/Ourprojects.css";
import { Col, Container, Row } from "react-bootstrap";
import useWindowDimensions from "../Utils/screenSize";

const project1Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195147/project1_orgklj_nh3grp_t7kuna.webp";
const project1Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058864/191-101_a_z8ws69.webp";
const project2Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195148/project2_tzfzy4_yhrbjg_ksczjo.webp";
const project2Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058865/191-101_b_n4icys.webp";
const project3Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195149/project3_t9oxvj_kzdpa2_ker9nf.webp";
const project3Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058865/191-101_c_woyafy.webp";
const project4Desktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195150/project4_idghdt_n84zyn.webp";
const project4Mobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058865/191-101_d_bxdyzr.webp";

const Ourprojects = () => {
  const { width } = useWindowDimensions();

  return (
    <Container className="projects_main_container">
      <div className="header_box">
        <h1>
          Projects We Have <span>Handled Successfully</span>
        </h1>
        <p>
          Help us work better by giving your valuable feedback for the overall
          journey experience.
        </p>
      </div>
      <div className="projects_box">
        <Row>
          <Col xs="6" sm="6" md="4">
            <div className="project_inner_box">
              <img
                src={width > 1024 ? project1Desktop : project1Mobile}
                alt="Project 1"
                className="project_img"
              />
            </div>
          </Col>
          <Col xs="6" sm="6" md="4">
            <div className="project_inner_box">
              <img
                src={width > 1024 ? project2Desktop : project2Mobile}
                alt="Project 2"
                className="project_img"
              />
            </div>
          </Col>
          <Col xs="6" sm="6" md="4">
            <div className="project_inner_box">
              <img
                src={width > 1024 ? project3Desktop : project3Mobile}
                alt="Project 3"
                className="project_img"
              />
            </div>
          </Col>
          <Col xs="6" sm="6" md="12">
            <div className="project_inner_box">
              <img
                src={width > 1024 ? project4Desktop : project4Mobile}
                alt="Project 4"
                className="project_img"
              />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Ourprojects;
