import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CSS/MyFleetPage.css";
import NavBar from "../components/NavBar";
import Header from "../components/header";
import Footer from "../components/Footer";
import Ourfleet from "../components/Ourfleet";
import WhatsAppContact from "../components/WhatsAppContact";

const FleetBanner =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195062/Background-min_cbftc0_oxdc65_iuahaz.webp";
const FleetDetail =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195119/fleet_detail_rk43wb_sqpegm.webp";
const FleetLeftCar =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195120/fleeT-left_car_yivdpt_hwratk.webp";

function MyFleet() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, []);
  return (
    <div
      className="main_my_fleet_container"
      style={{ backgroundColor: "#FFF" }}
    >
      <WhatsAppContact number="+917002187747" />
      <NavBar cname="Our Fleet"></NavBar>
      <Header
        heading="Luxury Fleet Of New Vehicles"
        banner={FleetBanner}
      ></Header>
      <Container>
        <div className="main_my_fleet_box">
          <Row>
            <Col md="5">
              <div className="fleet_image_detail_main">
                <img
                  src={FleetDetail}
                  alt="Fleet Detail"
                  className="fleet_details_image"
                />
                <img
                  className="fleet_detail_left_car"
                  src={FleetLeftCar}
                  alt="fleet left car"
                />
              </div>
            </Col>
            <Col md="7">
              <div className="fleet_details_box">
                <p className="title">Get the Ride That's Right For You </p>
                <p className="description">
                  Whether you need transportation for business, leisure, or
                  special events, our fleet of new cars and expert chauffeurs
                  guarantee a memorable experience. Book now and indulge in the
                  luxury of hassle-free travel! Feel free to customize this
                  content to fit your brand's tone and style. Good luck with
                  your business!
                </p>
                <button className="fleet_button" type="button">
                  Read More
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <Ourfleet />
      </Container>
      <Footer />
    </div>
  );
}

export default MyFleet;
