import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/fonts/font.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import BookingPage from "./pages/Bookingpage";
import Paymentpage from "./pages/Paymentpage";
import Aboutus from "./pages/aboutus";

import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Services from "./pages/ourservices";
import Gallery from "./pages/gallery";
import ContactUs from "./pages/contactus";
import MyFleet from "./pages/fleet";
import StripePaymentForm from "./components/StripePaymentForm";
import PrivacyPolicy from "./pages/privacypolicy";
import TermsAndConditions from "./pages/terms";

const routes = [
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/booking",
    element: <BookingPage />,
  },
  {
    path: "/payment",
    element: <Paymentpage />,
  },
  {
    path: "/confirm-pay",
    element: <StripePaymentForm />,
  },
  {
    path: "/aboutus",
    element: <Aboutus />,
  },
  {
    path: "/ourservices",
    element: <Services />,
  },
  {
    path: "/ourgallery",
    element: <Gallery />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/ourfleet",
    element: <MyFleet />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    element: <TermsAndConditions />,
  },
];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        {routes.length &&
          routes.map((data, _idx) => (
            <Route path={data.path} element={data.element} />
          ))}
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
