import React, { useState } from "react";
import { Alert, Container, Button, Form, Row, Col } from "react-bootstrap";
import Loader from "../Loader";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";

const Logo =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195118/ECS-Logo_uwfymd_uyow20.webp";

export const CheckoutForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  let [search] = new useSearchParams(location.search);
  const stripe = useStripe();
  const elements = useElements();
  const backendUrl = "https://ecs-api.markition.com/api/pay-now";
  const initialValues = {
    email: search.get("email"),
    cardholdername: "",
    from: search.get("from"),
    to: search.get("to"),
    time: search.get("time"),
    date: search.get("date"),
    category: search.get("category"),
    type: search.get("type"),
  };

  const [formData, setFormData] = useState(initialValues);
  const [loader, setLoader] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    if (!elements || !stripe) {
      setLoader(false);
      Swal.fire({
        title: "Error!",
        text: "Payment gateway is not loaded properly. Please try again later.",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#e12e2a",
        allowOutsideClick: false,
      });
      return;
    }

    try {
      const _CardElement = elements.getElement("card");

      const stripeResponse = await stripe.createToken(_CardElement);
      if (stripeResponse.error) {
        setLoader(false);
        Swal.fire({
          title: "Error!",
          text: stripeResponse.error.message,
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#e12e2a",
          allowOutsideClick: false,
        });
        return;
      }

      const stripeToken = stripeResponse.token;
      const response = await fetch(backendUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: "sar",
          paymentMethodType: "card",
          stripeToken,
          email: formData.email,
          amount: Number(props.amount),
          cardholdername: formData.cardholdername,
          locationFrom: initialValues.from,
          locationTo: initialValues.to,
          tripTime: initialValues.time,
          tripDate: initialValues.date,
          tripCategory: initialValues.category,
          tripType: initialValues.type,
        }),
      });

      const responce = await response.json();
      const clientSecret = responce.clientSecret.client_secret;

      if (response.status === 200) {
        setErrorShow(false);
        const resp = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement("card"),
            billing_details: {
              name: formData.cardholdername,
            },
          },
        });

        if (resp.error) {
          setLoader(false);
          Swal.fire({
            title: "Error!",
            text: resp.error.message,
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#e12e2a",
            allowOutsideClick: false,
          });
        } else if (resp.paymentIntent.status === "succeeded") {
          setLoader(false);
          Swal.fire({
            title: "Success!",
            text: "Payment Successful. Thank you.",
            icon: "success",
            confirmButtonText: "Book Another Ride",
            confirmButtonColor: "#e12e2a",
            allowOutsideClick: false,
          }).then(() => navigate({ pathname: "/" }, { replace: true }));
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An unexpected error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#e12e2a",
        allowOutsideClick: false,
      });
    } finally {
      setLoader(false);
    }
  };

  const getAMPM = () => {
    let time = Number(initialValues?.time?.split(":")[0]);
    if (time >= 13 && time <= 24) {
      return "PM";
    } else {
      return "AM";
    }
  };

  return (
    <>
      {loader && <Loader />}
      <Container className="payment-box">
        <Row>
          <Col md="6">
            <div className="header">
              <img src={Logo} alt="Logo" />
              <p>ECSProvider</p>
            </div>
            <div className="content">
              <p className="type-category">
                {initialValues.category} {initialValues.type}
              </p>
            </div>
            <div className="amount">SAR {Number(props.amount).toFixed(2)}</div>
            <div className="location">
              <p>
                {initialValues.from}, {initialValues.to} on {initialValues.date}{" "}
                {initialValues.time} {getAMPM()}
              </p>
            </div>
          </Col>
          <Col md="6">
            <div className="payment-form">
              <p>Pay with card</p>
              <form onSubmit={handleSubmit}>
                <div className="email_input">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    required
                    value={formData.email}
                    onChange={handleOnChange}
                    disabled
                  />
                </div>
                <p className="heading">Card information</p>
                <CardElement
                  options={{ iconStyle: "solid", hidePostalCode: true }}
                />
                <p className="heading">Cardholder name</p>
                <input
                  className="normal-input"
                  type="text"
                  name="cardholdername"
                  placeholder="Full name on card"
                  required
                  value={formData.cardholdername}
                  onChange={handleOnChange}
                />

                <br />
                {/* payment button here */}
                <div className="d-grid gap-2">
                  <Button
                    className="pay-button"
                    type="submit"
                    variant="danger"
                    disabled={!stripe || !elements}
                  >
                    Pay
                  </Button>
                  {errorShow && <Alert variant="danger">{errorMessage}</Alert>}
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
