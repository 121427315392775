import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../CSS/Bookwithus.css";
import getWindowDimensions from "../Utils/screenSize";
import effect1 from "../assets/effect1.png";

const icon1 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810954/Icon1_qqmjpg.png";
const icon2 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810955/Icon2_smjgb2.png";
const icon3 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810955/Icon3_qbykhr.png";
const icon4 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810955/Icon4_jjmgm3.png";
const mainimageDesktop =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195100/bodyimage_o87iuj_drwocd_tbslxz.webp";
const mainimageMobile =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058085/406-722_m36kro.webp";

const Withus = ({ image, title, subTitle, description }) => {
  const { width } = getWindowDimensions();

  return (
    <section className="main_body_container">
      {image === undefined && (
        <img src={effect1} className="effect1" alt="effect-img1" />
      )}
      <Container className="main_body_inner">
        <Row className="align-items-center">
          <Col md="4">
            <div className="image-container">
              <img
                src={width < 1024 ? mainimageMobile : mainimageDesktop}
                alt="Your Booking"
              />
            </div>
          </Col>
          <Col md="8">
            <div className="content-container">
              <h1>
                <span className="headingpart1">{title}</span>
                <br />
                <span className="headingpart2">{subTitle}</span>
              </h1>
              <p style={{ marginTop: "20px" }}>{description}</p>
              <Row style={{ marginTop: "40px" }} className="grid-items">
                <Col md="6">
                  <div className="grid-item">
                    <div className="header">
                      <h3>Safe | Comfortable Ride</h3>
                      <div>
                        <img src={icon1} alt="ico1" />
                      </div>
                    </div>
                    <p>
                      Extended leg and headroom with advanced safety features
                      make for a stress-free and comfortable ride.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="grid-item">
                    <div className="header">
                      <h3>Cost-Effective Options</h3>
                      <div>
                        <img src={icon2} alt="ico2" />
                      </div>
                    </div>
                    <p>
                      We design a program around Your budget, giving you
                      control.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="grid-item">
                    <div className="header">
                      <h3>No Hidden Charges</h3>
                      <div>
                        <img src={icon4} alt="ico4" />
                      </div>
                    </div>
                    <p>
                      We charge for what we deliver, no after-ride charges or
                      add-ons.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="grid-item">
                    <div className="header">
                      <h3>Duty of Health & Safety</h3>
                      <div>
                        <img src={icon3} alt="ico3" />
                      </div>
                    </div>
                    <p>
                      ECS continues to be the industry leader with strict
                      protocols in place that deliver a clean and safe ride,
                      anywhere you travel.
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div className="grid-item">
                    <div className="header">
                      <h3>We Are Never Closed</h3>
                      <div>
                        <img src={icon3} alt="ico3" />
                      </div>
                    </div>
                    <p>
                      24x7 Service support ensures your travel needs are met
                      anytime, anywhere in Riyadh.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Withus;
