import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../CSS/Footer.css";
import { Link } from "react-router-dom";

const logo =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195118/ECS-Logo_uwfymd_uyow20.webp";
const facebook =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195119/facebook_vhuxto_wcrdyq.webp";
const instagram =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195131/instagram_qcbkm9_j8belh.webp";
const linkedin =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195133/linkedin_bbh41b_c3wojw.webp";

const Footer = () => {
  return (
    <div className="main_footer_container">
      <Container className="footer_main_body">
        <Row>
          <Col md="4">
            <div className="inner-div1">
              <img src={logo} alt="" />
              <p>
                Executive Chauffeur Service KSA has been providing premium
                journeys to its clients for over a decade. Through strategic
                partnerships, we offer luxury transportation services in all
                major cities around the KSA. From a Mercedes Benz sedan to a
                luxury SUV, we have the right vehicle to make your journey
                exceptional.
              </p>
            </div>
          </Col>
          <Col md="3">
            <div className="inner-div2">
              <p className="title">Company</p>
              <Link to="/aboutus" className="company_links">
                <p>About Us</p>
              </Link>
              <Link to="/contactus" className="company_links">
                <p>Contact Us</p>
              </Link>
              <Link to="/ourservices" className="company_links">
                <p>Our Services</p>
              </Link>
              <Link to="/ourgallery" className="company_links">
                <p>Gallery</p>
              </Link>
              <Link to="/privacy" className="company_links">
                <p>Privacy Policy</p>
              </Link>
              <Link to="/terms" className="company_links">
                <p>Terms & Conditions</p>
              </Link>
            </div>
          </Col>
          <Col md="5">
            <div className="inner-div3">
              <p className="title">About Us</p>
              <div className="address1">
                <p className="key">Phone Number:</p>
                <p className="value">+966 53 762 8054</p>
              </div>
              <div className="address1">
                <p className="key">Email Address:</p>
                <p className="value">info@ecsprovider.com</p>
              </div>
              <div className="address2">
                <p className="key">Registered Office:</p>
                <p className="value">
                  Executive Chauffeur Service Provider Pvt. Ltd. Khadiman Rd,
                  beside Near State Bank Of India, 788806, Badarpur, Assam,
                  India
                </p>
              </div>
              <div className="address3">
                <p className="key">Corporate Office:</p>
                <p className="value">
                  MPR9+JHF, Al Amirah Sarah Bint Ahmad As Sadiri, Ad Dhubbat,
                  Riyadh 12623, Saudi Arabia
                </p>
              </div>
              <div className="icons">
                <div className="icon">
                  <img src={facebook} alt="fb" />
                </div>
                <div className="icon">
                  <img src={instagram} alt="insta" />
                </div>
                <div className="icon">
                  <img src={linkedin} alt="linkedln" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="footer_bottom">
          <p className="footer_bottom_text">
            <span>Proudly Powered by</span>
            <span className="red"> Markition Private Limited </span>
            <span>– Elevating Digital Experiences</span>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
