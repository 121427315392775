import React, { useState } from "react";
import "../CSS/Comments.css";
import effect1 from "../assets/effect1.png";
import effect2 from "../assets/effect2.png";
import { Container } from "react-bootstrap";

const CommentComponent = ({ comments }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 3;

  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments = comments.slice(
    indexOfFirstComment,
    indexOfLastComment
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      const starClass = i <= rating ? "star-yellow" : "star-gray";
      stars.push(
        <span key={i} className={`star ${starClass}`}>
          ★
        </span>
      );
    }
    return stars;
  };

  return (
    <section className="comment-component">
      <img src={effect1} className="effect1" alt="effect-img1" />
      <Container>
        <h1>What</h1>
        <h1 className="red_heading">People Say About Us</h1>
        <p className="text"></p>
        <div className="comment-list">
          {currentComments.map((comment, index) => (
            <div key={index} className="comment-item">
              <div className="comment-content">
                <p>{comment.comment}</p>
                <div className="inverted-commas-container">
                  <div className="inverted-commas" />
                </div>
              </div>
              <div className="comment-owner">
                {/* <img src={comment.image} alt="" /> */}
                <div className="name_rating">
                  <span>{comment.name}</span>
                  <div className="rating">{renderStars(comment.rating)}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-dots">
          {Array(Math.ceil(comments.length / commentsPerPage))
            .fill(null)
            .map((_, index) => (
              <span
                key={index}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => paginate(index + 1)}
              >
                ●
              </span>
            ))}
        </div>
      </Container>
      <img src={effect2} className="effect2" alt="effect-img2" />
    </section>
  );
};

export default CommentComponent;
