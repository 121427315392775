import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "../CSS/AirportTransfers.css";
import useWindowDimensions from "../Utils/screenSize";

// Desktop Images
const airport1 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195039/airport1_ylo3bk_zeaom2.webp";
const airport2 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195040/airport2_tsbdbr_xsxp7t.webp";
const airport3 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195045/airport3_bhkje5_qxbswu.webp";
const airport4 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195047/airport4_bkct29_hlkunv.webp";
const airport5 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195049/airport5_t9gscp_q8s40o.webp";
const airport6 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1720195050/airport6_zwjfug_iru8wj.webp";

// Mobile Images
const airport1M =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058633/191-191_d_c9agfr.webp";
const airport2M =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058635/191-191_f_djydsa.webp";
const airport3M =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058633/191-191_e_nahhlv.webp";
const airport4M =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058632/191-191_a_wwpef1.webp";
const airport5M =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058632/191-191_b_umk5gj.webp";
const airport6M =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1721058633/191-191_c_rmgvik.webp";

const MobileImages = [
  airport1M,
  airport2M,
  airport3M,
  airport4M,
  airport5M,
  airport6M,
];

const AirportTransfers = () => {
  const { width } = useWindowDimensions();

  return (
    <Container className="airport_main_container">
      <div className="header_box">
        <h1>
          Popular Airport <span>Transfers</span>
        </h1>
        <p>
          Help us work better by giving your valuable feedback for the overall
          journey experience.
        </p>
      </div>
      {width >= 1025 ? (
        <div className="airports_box">
          <Row>
            <Col md="6">
              <Row>
                <Col md="12">
                  <div className="airport_inner_box">
                    <img
                      src={airport1}
                      alt="Airport 1"
                      className="airport_img1"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="airport_inner_box">
                    <img
                      src={airport2}
                      alt="Airport 2"
                      className="airport_img"
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="airport_inner_box">
                    <img
                      src={airport3}
                      alt="Airport 3"
                      className="airport_img"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <Col md="6">
                  <div className="airport_inner_box">
                    <img
                      src={airport4}
                      alt="Airport 4"
                      className="airport_img"
                    />
                  </div>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="12">
                      <div className="airport_inner_box">
                        <img
                          src={airport5}
                          alt="Airport 5"
                          className="airport_img"
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div className="airport_inner_box">
                        <img
                          src={airport6}
                          alt="Airport 6"
                          className="airport_img"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="airports_box">
          <Row>
            {MobileImages.length &&
              MobileImages.map((data, _idx) => (
                <Col xs="6" sm="6">
                  <div className="airport_inner_box">
                    <img
                      src={data}
                      alt={`Airport ${_idx}`}
                      className="airport_img1"
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default AirportTransfers;
