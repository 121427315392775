import React from "react";
import "./style.css";

const Loader = (props) => {
  return props.show
    ? props.show
    : true && (
        <div className="loaderOuterBox">
          <div class="loader"></div>
        </div>
      );
};

export default Loader;
