/* global google */
import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../CSS/Inputfields.css";
import {
  GoogleMap,
  Marker,
  MarkerF,
  Autocomplete,
  DirectionsService,
  DirectionsRenderer,
  LoadScript,
} from "@react-google-maps/api";

import {
  createSearchParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import "../CSS/Carscards.css";
import moment from "moment";
import API from "../Utils/endpoints";
import Loader from "./Loader";

// Icons
const backIcon =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810901/back_qywzwg.png";
const location =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810959/location_Icon_oyapgw.png";
const rightredarrow =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810967/place-dir_yukaim.png";
const Dots =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810944/dots-vertical_qeaomy.png";
const LuggageIco =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810960/luggage_fgbxjx.png";
const PassengerIco =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810964/passenger_q7ggc5.png";
const PickupIco =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810965/pickup_n7qmcx.png";
const timeIco =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810989/time_blormg.png";
const delayIco =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810944/delay_qkld2r.png";
const Work1 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810993/work1_d0aycz.png";
const Work2 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810994/work2_mpaq5h.png";
const Work3 =
  "https://res.cloudinary.com/dzgzig4vx/image/upload/v1714810994/work3_ejpmua.png";

const libraries = ["places"];

const carsPerPage = 3;
const listForCars = [];

const Inputfields = ({ selectedBtn }) => {
  const [loader, setLoader] = useState(false);
  const [cars, setCars] = useState([]);
  const [carsToShow, setCarsToShow] = useState([]);
  const [count, setCount] = useState(1);
  const location6 = useLocation();
  var [search] = new useSearchParams(location6.search);
  var [inputvalues, setinputvalues] = useState({
    from: search.get("from"),
    to: search.get("to"),
    pickdate: search.get("pickdate"),
    picktime: search.get("picktime"),
    passenger: search.get("passenger"),
    hours: search.get("hours"),
    location11: search.get("location1"),
    location22: search.get("location2"),
    currency: search.get("category"),
    bookType: Number(search.get("selectedBtn")),
  });

  const [map, setMap] = useState(null);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [rend, Setrend] = useState(false);
  const [directions, setDirections] = useState(null);

  var [inputs, Setinputs] = useState({});
  var [location1, setlocation1] = useState({});
  var [location2, setlocation2] = useState({});

  var [place, setPlace] = useState(null);
  const [flag, setFlag] = useState(0);

  const [marker1, setMarker1] = useState({
    lat: Number(JSON.parse(search.get("location1")).lat),
    lng: Number(JSON.parse(search.get("location1")).lng),
  });
  const [marker2, setMarker2] = useState({
    lat: Number(JSON.parse(search.get("location2")).lat),
    lng: Number(JSON.parse(search.get("location2")).lng),
  });

  const getVehicles = async () => {
    setLoader(true);
    let response = await API.GetVehiclesAPI(
      inputvalues.from,
      inputvalues.to,
      inputvalues.picktime,
      inputvalues.pickdate,
      inputvalues.passenger,
      inputvalues.currency // category
    );

    if (response.data.success) {
      setCars(response.data.result);
      setIsDataAvailable(true);
      setLoader(false);
    } else {
      setLoader(false);
      setIsDataAvailable(false);
    }
  };

  const getCarsLoopToShow = useCallback((count) => {
    for (
      let i = count * carsPerPage - carsPerPage;
      i < carsPerPage * count;
      i++
    ) {
      console.log("list ------> ", i, cars, cars[i]);
      if (cars[i] !== undefined) {
        listForCars.push(cars[i]);
      }
    }
    setCarsToShow(listForCars);
  });

  const handleShowMoreCars = () => {
    setCount((prev) => prev + 1);
    getCarsLoopToShow(count);
  };

  useEffect(() => {
    setlocation1(inputvalues.location11);
    setlocation2(inputvalues.location22);

    getVehicles();
    Setinputs(inputvalues);
    navigate({ pathname: location1.pathname });
    Setrend(true);
  }, [inputvalues]);

  // useEffect(() => {
  //   setCount((prev) => prev + 1);
  //   getCarsLoopToShow(count);
  // }, [count, getCarsLoopToShow]);

  const mapContainerStyle = {
    width: "100%",
    height: "70vh",
  };

  const center = {
    lat: JSON.parse(inputvalues.location11).lat
      ? JSON.parse(inputvalues.location11).lat
      : {}, // Replace with your desired latitude
    lng: JSON.parse(inputvalues.location22).lng
      ? JSON.parse(inputvalues.location22).lng
      : {}, // Replace with your desired longitude
  };

  const onPlaceChanged = async () => {
    if (place !== null && place.getPlace() !== undefined) {
      const location = place.getPlace().geometry.location;
      console.log("Place changed:", location.lat(), location.lng());
    }
    console.log(place);
  };
  const navigate = useNavigate();

  const handleButtonClick = (name, image, type, category, passengers, fare) => {
    if (rend === true) {
      navigate({
        pathname: "/payment",
        search: createSearchParams({
          picktime: inputvalues.picktime,
          pickdate: inputvalues.pickdate,
          passenger: inputvalues.passenger,
          from: inputvalues.from,
          to: inputvalues.to,
          car_name: name,
          car_image: image,
          car_category: category,
          car_type: type,
          car_passengers: passengers,
          car_fare: fare,
          airport: selectedBtn.name,
          hours: inputvalues.hours,
          bookType:
            inputvalues.bookType === 0
              ? "Airport Transfer"
              : inputvalues.bookType === 1
              ? "Hourly"
              : "Full Day",
        }).toString(),
        state: inputvalues,
      });
    }
  };

  const directionsOptions = {
    destination: JSON.parse(inputvalues.location11)
      ? JSON.parse(inputvalues.location11)
      : {},
    origin: JSON.parse(inputvalues.location22)
      ? JSON.parse(inputvalues.location22)
      : {},
    travelMode: "DRIVING",
  };

  const directionsCallback = (response) => {
    if (response !== null && response.status === "OK") {
      setDirections(response);
      setFlag(1);
    } else {
      console.log("Error with directions response:", response);
    }
  };

  const handleMapLoad = (map) => {
    setMap(map);
  };

  const handleupdate = () => {
    setinputvalues(inputs);
  };

  const getAMPM = () => {
    let time = Number(inputvalues?.picktime?.split(":")[0]);
    if (time >= 13 && time <= 24) {
      return "PM";
    } else {
      return "AM";
    }
  };

  const handleBack = () => {
    navigate("/", { replace: true });
  };

  return (
    <div>
      {loader && <Loader />}
      {marker1.lat && marker1.lng && marker2.lat && marker2.lng && (
        <LoadScript
          googleMapsApiKey="AIzaSyCbq5JUmsTiyxV9Fl4R-AAsRfeJCpJKzxw"
          libraries={libraries}
        >
          <div className="main_booking_form_container">
            <Container>
              <div className="booking_form_inputs">
                <Row>
                  {selectedBtn &&
                    selectedBtn.inputs.length &&
                    selectedBtn.inputs.map((inputs, indx) => (
                      <Col xs="6" sm="6" md="4" xl="4" xxl="2">
                        <div key={indx} className="input">
                          {inputs.length === 2 ? (
                            <div className="input_field">
                              {inputs[0].type !== "location" &&
                              inputs[0].type !== "date" &&
                              inputs[0].type !== "time" ? (
                                <label htmlFor={inputs[0].for}>
                                  <div className="input_field1">
                                    <input
                                      type={inputs[0].type}
                                      id={inputs[0].id}
                                      placeholder={inputs[0].placeholder}
                                    />
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : inputs[0].type !== "date" &&
                                inputs[0].type !== "time" ? (
                                <label htmlFor={inputs[0].for}>
                                  <div className="input_field1">
                                    <Autocomplete
                                      // onLoad={(autocomplete) => setPlace(autocomplete)}
                                      onPlaceChanged={onPlaceChanged}
                                    >
                                      <div>
                                        <input
                                          type="text"
                                          id={inputs[0].id}
                                          placeholder={inputs[0].placeholder}
                                          // value={inputvalues[id]}
                                        />
                                        <div className="underline"></div>
                                      </div>
                                    </Autocomplete>
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : (
                                <label htmlFor={inputs[0].for}>
                                  <div className="input_field1">
                                    <input
                                      type="text"
                                      placeholder={inputs[0].placeholder}
                                      onChange={(e) =>
                                        console.log(e.target.value)
                                      }
                                      onFocus={(e) => (e.target.type = "date")}
                                      onBlur={(e) => (e.target.type = "text")}
                                    />
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              )}
                              {inputs[1].type !== "location" &&
                              inputs[1].type !== "time" ? (
                                <label
                                  htmlFor={inputs[1].for}
                                  className="second"
                                >
                                  <div className="input_field1">
                                    <input
                                      type={inputs[1].type}
                                      id={inputs[1].id}
                                      placeholder={inputs[1].placeholder}
                                    />
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : inputs[1].type !== "date" &&
                                inputs[1].type !== "time" ? (
                                <label
                                  htmlFor={inputs[1].for}
                                  className="second"
                                >
                                  <div className="input_field1">
                                    <Autocomplete
                                      // onLoad={(autocomplete) => setPlace(autocomplete)}
                                      onPlaceChanged={onPlaceChanged}
                                    >
                                      <div>
                                        <input
                                          type="text"
                                          placeholder={inputs[1].placeholder}
                                        />
                                        <div className="underline"></div>
                                      </div>
                                    </Autocomplete>
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : (
                                <label
                                  htmlFor={inputs[1].for}
                                  className="second"
                                >
                                  <div className="input_field1">
                                    <input
                                      type="text"
                                      placeholder={inputs[1].placeholder}
                                      onChange={(e) =>
                                        console.log(e.target.value)
                                      }
                                      onFocus={(e) => (e.target.type = "time")}
                                      onBlur={(e) => (e.target.type = "text")}
                                    />
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              )}
                            </div>
                          ) : (
                            <div className="input_field">
                              {inputs[0].type !== "category" &&
                              inputs[0].type !== "location" &&
                              inputs[0].type !== "time" &&
                              inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <div className="input_field2">
                                    <p className="label-inp">
                                      {inputs[0].label}
                                    </p>
                                    <span className="value-inp">
                                      {inputvalues[inputs[0].id]}
                                    </span>
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : inputs[0].type !== "location" &&
                                inputs[0].type !== "time" &&
                                inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <div className="input_field2">
                                    <p className="label-inp">
                                      {inputs[0].label}
                                    </p>
                                    <span className="value-inp">
                                      {inputvalues[inputs[0].id]}
                                    </span>
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : inputs[0].type !== "time" &&
                                inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <div className="input_field2">
                                    <p className="label-inp">
                                      {inputs[0].label}
                                    </p>
                                    {inputs[0].id === "from" ? (
                                      <span className="value-inp">
                                        {inputvalues[inputs[0].id]}
                                      </span>
                                    ) : (
                                      <span className="value-inp">
                                        {inputvalues[inputs[0].id]}
                                      </span>
                                    )}
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : inputs[0].type !== "date" ? (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <div className="input_field2">
                                    <p className="label-inp">
                                      {inputs[0].label}
                                    </p>
                                    <span className="value-inp">
                                      {inputvalues["picktime"]}
                                    </span>
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              ) : (
                                <label
                                  htmlFor={inputs[0].for}
                                  className="different"
                                >
                                  <div className="input_field2">
                                    <p className="label-inp">
                                      {inputs[0].label}
                                    </p>
                                    <span className="value-inp">
                                      {inputvalues["pickdate"]}
                                    </span>
                                    <div className="underline"></div>
                                  </div>
                                </label>
                              )}
                            </div>
                          )}
                        </div>
                      </Col>
                    ))}
                </Row>
                {/* <div className="booking_update_btn">
                <button type="submit" onClick={handleupdate}>
                  Update
                </button>
              </div> */}
              </div>
            </Container>
          </div>
          <Container>
            <div className="booking_details_box_container">
              <Row>
                <Col md="7">
                  <p onClick={handleBack} className="back-btn">
                    <img src={backIcon} alt="back" /> &nbsp; Back
                  </p>
                  <h2 className="heading">Choose Your Vehicle</h2>
                  <div className="address_details">
                    <div className="address_box">
                      <span className="text_from">{inputvalues.from}</span>
                      <img src={rightredarrow} alt="arrow icon" />
                      <span className="text_to">{inputvalues.to}</span>
                    </div>
                    <div className="address_box2">
                      <span className="text_from">
                        {isDataAvailable && cars.length} Search{" "}
                        {isDataAvailable && cars.length > 1
                          ? "Results"
                          : "Result"}
                      </span>
                      <span className="text_to">
                        {moment(inputvalues.pickdate).format("DD-MMM-YYYY")} -{" "}
                        {`${inputvalues.picktime} ${getAMPM()}`}
                      </span>
                    </div>
                  </div>
                  {isDataAvailable ? (
                    <div className="booking_cars_box">
                      <h2 className="heading_type">{inputvalues.currency}</h2>
                      {cars.map((car, carIndex) => (
                        <div
                          key={carIndex}
                          className={`car-card ${
                            carIndex === cars.length - 1 ? "last" : ""
                          }`}
                        >
                          <div className="car-header">
                            <img
                              className="car-image"
                              src={car.img}
                              alt={car.name}
                            />
                          </div>
                          <div className="passengers-price">
                            <p className="car-type">
                              {car.name} {car.color}
                            </p>
                            <p className="car-passengers">{`${inputvalues.passenger} passengers`}</p>
                          </div>
                          <div className="passengers-price">
                            <p className="car-color">
                              {`${car.category} ${car.type}`}
                            </p>
                            <p className="car-fare">{`${
                              inputvalues.bookType === 1
                                ? car.fare * inputvalues.hours
                                : car.fare
                            } SAR`}</p>
                          </div>
                          <p className="example-text">
                            eg: Toyota Prius Or Similar Car
                          </p>
                          <div className="luggage-passesnger">
                            <div className="passenger">
                              <img src={PassengerIco} alt="Passenger" />
                              <span>Max {car.pass_cap}</span>
                            </div>
                            <div className="luggage">
                              <img src={LuggageIco} alt="Luggage" />
                              <span>Max 3</span>
                            </div>
                          </div>
                          <div className="button_container">
                            <button
                              className="book-now"
                              onClick={() => {
                                handleButtonClick(
                                  car.name,
                                  car.img,
                                  car.type,
                                  car.category,
                                  car.pass_cap,
                                  car.fare
                                );
                              }}
                            >
                              Book Now
                            </button>
                          </div>
                          <div className="additional-info">
                            <div className="pickup">
                              <img src={PickupIco} alt="Pickup" />
                              <span>Meet & Greet</span>
                            </div>
                            <div className="waiting">
                              {" "}
                              <img src={timeIco} alt="Time" />
                              <span>45 Minutes Wait Time</span>
                            </div>
                            <div className="delays">
                              {" "}
                              <img src={delayIco} alt="delay" />
                              <span>Flight Delays Info</span>
                            </div>
                          </div>
                        </div>
                      ))}
                      {/* <button onClick={() => handleShowMoreCars()}>
                        Load More
                      </button> */}
                    </div>
                  ) : (
                    <div>No cars available for the given details</div>
                  )}
                </Col>
                <Col md="5">
                  <div className="map-container">
                    {inputvalues.location11 && inputvalues.location22 && (
                      <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        center={center}
                        zoom={12}
                        onLoad={handleMapLoad}
                      >
                        {map && (
                          <DirectionsService
                            options={directionsOptions}
                            callback={flag === 0 && directionsCallback}
                          />
                        )}
                        {map && directions && (
                          <DirectionsRenderer
                            options={{ suppressMarkers: true }}
                            directions={directions}
                          />
                        )}
                        <MarkerF position={marker1} />
                        <MarkerF position={marker2} />
                      </GoogleMap>
                    )}
                    <div className="map_details">
                      <div className="address">
                        <img src={location} alt="" />
                        <p>{inputvalues.from}</p>
                      </div>
                      <img className="dots" src={Dots} alt="dots icon" />
                      <div className="address">
                        <img src={location} alt="" />
                        <p>{inputvalues.to}</p>
                      </div>
                    </div>
                  </div>
                  <div className="booking_information">
                    <div className="header">
                      <h2 className="heading">How does ECS Work?</h2>
                      <p className="subheading">
                        Simplify Journeys with Quality Assurance
                      </p>
                    </div>
                    <div className="work_box">
                      <img src={Work1} alt="Work Ico 1" />
                      <div className="work_details">
                        <p className="text1">Use our Website for Booking</p>
                        <p className="text2">
                          Enter all your necessary journey details through our
                          website.
                        </p>
                      </div>
                    </div>
                    <div className="work_box">
                      <img src={Work2} alt="Work Ico 2" />
                      <div className="work_details">
                        <p className="text1">Your Ride</p>
                        <p className="text2">
                          Get Email and SMS updates from our side related to the
                          arrival of your car.
                        </p>
                      </div>
                    </div>
                    <div className="work_box_last">
                      <img src={Work3} alt="Work Ico 3" />
                      <div className="work_details">
                        <p className="text1">Your Feedback</p>
                        <p className="text2">
                          Help us work better by giving your valuable feedback
                          for the overall journey experience.
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </LoadScript>
      )}
    </div>
  );
};

export default Inputfields;
